import React, { useContext } from 'react';
import Login from '~app/components/Login';
import Landing from '~app/components/Landing';
import Chapter from '~app/components/Chapter';
import Account from '~app/components/Account';
import { chapterContents } from '~app/config/chapterContents';
import { ApiContext } from '~app/api/context';
import { UserProgressContext } from '~app/userprogress/context';

export const Story = ({ chapterNumber }) => {
  const userProgressContext = useContext(UserProgressContext);
  const { authToken } = useContext(ApiContext);
  const getStoryState = storyView => {
    if (storyView === 'chapter') {
      return (
        <Chapter
          chapterNumber={chapterNumber}
          contents={chapterContents[chapterNumber - 1]}
        />
      );
    } else if (storyView === 'account') {
      return <Account />;
    } else {
      return <Landing />;
    }
  };

  return authToken ? getStoryState(userProgressContext.storyView) : <Login />;
};

export default Story;
