import React, { useContext } from 'react';
import FooterBar from '~app/components/FooterBar';
import { UserProgressContext } from '~app/userprogress/context';
import constants from '~app/utils/constants';

export const Landing = () => {
  const userProgressContext = useContext(UserProgressContext);
  const goToChapter = chapNum => {
    userProgressContext.setCurrentChapter(chapNum);
    userProgressContext.setStoryView('chapter');
    userProgressContext.setLandingState('complete_part');
  };

  const readyToProceed =
    // true if user lands on landing page after logging in or visiting a non-chapter page and
    // they've already made some progress and they're not on the last
    // chapter of the furthest part to which they have access
    userProgressContext.landingState === 'initial' &&
    ((userProgressContext.furthestPart === 1 &&
      userProgressContext.currentChapter < constants.LAST_PART_ONE_CHAP) ||
      (userProgressContext.furthestPart === 2 &&
        userProgressContext.currentChapter < constants.LAST_PART_TWO_CHAP));

  const getLandingTitle = () => {
    let landingTitle;
    if (
      userProgressContext.landingState === 'initial' &&
      userProgressContext.currentChapter < constants.LAST_PART_TWO_CHAP
    ) {
      if (
        userProgressContext.passedGames.size +
          userProgressContext.passedVideos.size <
        1
      ) {
        landingTitle = 'Welcome to the ShootSafe Story!';
        //if user hasn't completed a single game or video, we'll treat them as a new arrival
      } else {
        landingTitle = 'Welcome back!';
      }
    } else {
      landingTitle = 'Congratulations!';
    }
    return landingTitle;
  };
  const getLandingSubTitle = () => {
    let landingSubTitle;
    if (readyToProceed) {
      if (
        userProgressContext.passedGames.size +
          userProgressContext.passedVideos.size <
        1
      ) {
        landingSubTitle = 'Your account is ready to start the ShootSafe Story!';
        //if user hasn't completed a single game or video, we'll treat them as a new arrival
      } else {
        landingSubTitle =
          userProgressContext.currentChapter > 0
            ? `The last chapter you completed was Chapter ${userProgressContext.currentChapter}.\n`
            : '';
        landingSubTitle += 'Dive back into the story!';
      }
    } else {
      landingSubTitle =
        userProgressContext.furthestPart === 2
          ? 'You have completed the entire ShootSafe Story!\nYou can now revisit any part of the ShootSafe Story.'
          : 'You have completed Part 1 of the ShootSafe Story and Part 2 is not open.\nYou can now revisit Part 1 of the ShootSafe Story.';
    }
    return landingSubTitle;
  };

  const buttonHandler = () => {
    if (readyToProceed) {
      goToChapter(userProgressContext.currentChapter + 1);
      if (
        userProgressContext.furthestChapter <=
        userProgressContext.currentChapter
      ) {
        userProgressContext.setFurthestChapter(
          userProgressContext.currentChapter + 1,
        );
      }
    } else {
      goToChapter(1);
    }
  };
  const buttonLabel = () => {
    if (readyToProceed) {
      return 'Chapter ' + (+userProgressContext.currentChapter + 1);
    } else {
      return 'Chapter 1';
    }
  };
  return (
    <div className="landingWrap">
      <div className="landingWrap_contents">
        <h1>{getLandingTitle()}</h1>
        <h3>{getLandingSubTitle()}</h3>
        <input
          type="submit"
          value={buttonLabel()}
          onClick={() => buttonHandler()}
        />
      </div>
      <FooterBar />
    </div>
  );
};

export default Landing;
