import React, { useContext } from 'react';
import { UserProgressContext } from '~app/userprogress/context';

export const ChapterNav = ({ chapterNumber, isCollapsed = false }) => {
  const userProgressContext = useContext(UserProgressContext);
  const furthestChapterUnlocked = userProgressContext.furthestChapter;
  const goToChapter = chapNum => {
    userProgressContext.setCurrentChapter(chapNum);
    document.getElementsByTagName('html')[0].scrollTo(0, 0);
  };

  return (
    <nav className={`chapterNav ${isCollapsed ? 'collapsed' : ''}`}>
      <ul className="chapterNav__parts">
        <li>
          <ul className="chapterNav__part">
            <li className={chapterNumber < 4 ? 'active' : ''}>Part 1</li>
          </ul>
          <ul className="chapterNav__chapters">
            <li
              className={`${chapterNumber === 1 ? 'active' : ''} ${
                furthestChapterUnlocked >= 1 ? 'unlocked' : ''
              }`}
            >
              <button
                onClick={() => {
                  goToChapter(1);
                }}
                type="button"
              >
                Ch<span>apter</span> 1
              </button>
            </li>
            <li
              className={`${chapterNumber === 2 ? 'active' : ''} ${
                furthestChapterUnlocked >= 2 ? 'unlocked' : ''
              }`}
            >
              <button
                onClick={() => {
                  goToChapter(2);
                }}
                disabled={furthestChapterUnlocked < 2}
                type="button"
              >
                Ch<span>apter</span> 2
              </button>
            </li>
            <li
              className={`${chapterNumber === 3 ? 'active' : ''} ${
                furthestChapterUnlocked >= 3 ? 'unlocked' : ''
              }`}
            >
              <button
                onClick={() => {
                  goToChapter(3);
                }}
                disabled={furthestChapterUnlocked < 3}
                type="button"
              >
                Ch<span>apter</span> 3
              </button>
            </li>
          </ul>
        </li>
        <li>
          <ul className="chapterNav__part">
            <li className={chapterNumber > 3 ? 'active' : ''}>Part 2</li>
          </ul>
          <ul className="chapterNav__chapters">
            <li
              className={`${chapterNumber === 4 ? 'active' : ''} ${
                furthestChapterUnlocked >= 4 &&
                userProgressContext.furthestPart > 1
                  ? 'unlocked'
                  : ''
              }`}
            >
              <button
                onClick={() => {
                  goToChapter(4);
                }}
                disabled={
                  furthestChapterUnlocked < 4 ||
                  userProgressContext.furthestPart < 2
                }
                type="button"
              >
                Ch<span>apter</span> 4
              </button>
            </li>
            <li
              className={`${chapterNumber === 5 ? 'active' : ''} ${
                furthestChapterUnlocked >= 5 &&
                userProgressContext.furthestPart > 1
                  ? 'unlocked'
                  : ''
              }`}
            >
              <button
                onClick={() => {
                  goToChapter(5);
                }}
                disabled={
                  furthestChapterUnlocked < 5 ||
                  userProgressContext.furthestPart < 2
                }
                type="button"
              >
                Ch<span>apter</span> 5
              </button>
            </li>
            <li
              className={`${chapterNumber === 6 ? 'active' : ''} ${
                furthestChapterUnlocked >= 6 &&
                userProgressContext.furthestPart > 1
                  ? 'unlocked'
                  : ''
              }`}
            >
              <button
                onClick={() => {
                  goToChapter(6);
                }}
                disabled={
                  furthestChapterUnlocked < 6 ||
                  userProgressContext.furthestPart < 2
                }
                type="button"
              >
                Ch<span>apter</span> 6
              </button>
            </li>
            <li
              className={`${chapterNumber === 7 ? 'active' : ''} ${
                furthestChapterUnlocked >= 7 &&
                userProgressContext.furthestPart > 1
                  ? 'unlocked'
                  : ''
              }`}
            >
              <button
                onClick={() => {
                  goToChapter(7);
                }}
                disabled={
                  furthestChapterUnlocked < 7 ||
                  userProgressContext.furthestPart < 2
                }
                type="button"
              >
                Ch<span>apter</span> 7
              </button>
            </li>
          </ul>
        </li>
      </ul>
    </nav>
  );
};

export default ChapterNav;
