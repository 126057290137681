import React from 'react';
import FooterBar from '~app/components/FooterBar';
import { useHistory } from 'react-router-dom';

const NotFound = () => {
  const history = useHistory();

  const goToLanding = () => {
    history.push('/');
  };
  return (
    <div className="page__not-found">
      <div>
        <img
          src={require('~app/resources/404.svg')}
          alt="Page Not Found"
          width="346"
          height="126"
        />
        <h2>Error 404 - Page not Found</h2>
        <h3>
          Oh no, we missed the shot and can't find the page you are looking for.
        </h3>
        <button
          onClick={() => {
            goToLanding();
          }}
          type="button"
        >
          Go back to the story
        </button>
      </div>

      <FooterBar />
    </div>
  );
};

export default NotFound;
