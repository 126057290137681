import React, { useRef, useEffect, useContext, useState } from 'react';
import ChapterNav from '~app/components/ChapterNav';
import Graphic from '~app/components/Graphic';
import VimeoPlayer from '~app/components/VimeoPlayer';
import Unity from '~app/components/Unity';
import * as images from '~app/config/images';
import FooterBar from '~app/components/FooterBar';
import { useOnScreen } from '~app/utils/hooks';
import { UserProgressContext } from '~app/userprogress/context';
import { useMutation } from 'react-query';
import { chapterContents } from '~app/config/chapterContents';
import { ApiContext } from '~app/api/context';
import { apiPost } from '~app/api/hooks';
import constants from '~app/utils/constants';
import Overlay from '~app/components/Overlay';

export const Chapter = props => {
  const apiContext = useContext(ApiContext);
  const heroRef = useRef();
  const bgRef = useRef();
  const heroOnScreen = useOnScreen(heroRef);
  const userProgressContext = useContext(UserProgressContext);
  const [chapHasLockedLessons, setChapHasLockedLessons] = useState(false);
  const [showReward, setShowReward] = useState(false);
  const [nextStoryView, setNextStoryView] = useState('chapter');
  const [showNetworkErrorMessage, setShowNetworkErrorMessage] = useState(false);

  const updateLockStatus = () => {
    setTimeout(() => {
      setChapHasLockedLessons(
        document.getElementsByClassName('panel-locks').length > 0,
      );
    }, 0); //allow call stack to finish before executing
  };

  useEffect(() => {
    updateLockStatus();
    // If user hasn't completed this chapter yet, scroll them to the last lockable content
    // that has already been unlocked (returning them roughly to where they left off
    // if they departed chapter mid-lesson)
    if (
      userProgressContext.furthestChapterCompleted <
        userProgressContext.currentChapter &&
      document.getElementsByClassName('panel-unlocked').length > 0
    ) {
      // Convert nodelist to array, extract the last element
      const lastCompletedLesson = [
        ...document.querySelectorAll('.panel-unlocked'),
      ].pop();
      // Scroll it into view for desktop
      lastCompletedLesson.scrollIntoView();
      // Safari for iOS needs a little more time to render
      // page before it can correctly implement scrollIntoView
      setTimeout(() => {
        lastCompletedLesson.scrollIntoView();
      }, 500);
    }
  }, [
    userProgressContext.furthestChapterCompleted,
    userProgressContext.currentChapter,
  ]);

  const goToLanding = () => {
    userProgressContext.setStoryView('landing');
  };

  const advanceToNextChapter = () => {
    setShowReward(false);
    userProgressContext.setCurrentChapter(
      userProgressContext.currentChapter + 1,
    );
    document.getElementsByTagName('body')[0].scrollTo(0, 0);
  };

  const [doFinishChapter] = useMutation(
    () =>
      apiPost(
        apiContext.url + '/event',
        {
          timestamp: new Date().toISOString(),
          type: 'chapter',
          type_identifier: '' + userProgressContext.currentChapter,
          description:
            chapterContents[userProgressContext.currentChapter - 1].title,
          action: 'complete',
        },
        {
          fetchOptions: {
            headers: { Authorization: `JWT ${apiContext.authToken}` },
          },
        },
      ),
    {
      onSuccess: data => {
        setShowNetworkErrorMessage(false);
        const userAlreadyCompletedChapter =
          userProgressContext.furthestChapterCompleted >=
          userProgressContext.currentChapter;
        if (
          (userProgressContext.furthestPart > 1 &&
            userProgressContext.currentChapter >=
              constants.LAST_PART_TWO_CHAP) ||
          (userProgressContext.furthestPart < 2 &&
            userProgressContext.currentChapter >= constants.LAST_PART_ONE_CHAP)
        ) {
          //if user finishes section one or two,
          //update furthestChapterCompleted and send them back to landing
          if (
            userProgressContext.furthestChapterCompleted <
            userProgressContext.currentChapter
          ) {
            userProgressContext.setFurthestChapterCompleted(
              userProgressContext.currentChapter,
            );
          }
          userProgressContext.setLandingState('completion');
          if (userAlreadyCompletedChapter) {
            userProgressContext.setStoryView('landing');
          } else {
            setNextStoryView('landing');
            setShowReward(true);
          }
        } else {
          //otherwise take them to the next chapter
          if (
            userProgressContext.furthestChapter <=
            userProgressContext.currentChapter
          ) {
            userProgressContext.setFurthestChapter(
              userProgressContext.currentChapter + 1,
            );
          }
          if (
            userProgressContext.furthestChapterCompleted <
            userProgressContext.currentChapter
          ) {
            userProgressContext.setFurthestChapterCompleted(
              userProgressContext.currentChapter,
            );
          }
          if (userAlreadyCompletedChapter) {
            advanceToNextChapter();
          } else {
            setShowReward(true);
          }
        }
      },
      onError: error => {
        setShowNetworkErrorMessage(false);
        setTimeout(() => {
          setShowNetworkErrorMessage(true);
        }, 700);
      },
    },
  );

  const setTranslate = (xPos, yPos, el) => {
    el.style.transform = 'translate3d(' + xPos + 'px, ' + yPos + 'px, 0)';
  };

  useEffect(() => {
    const scrollLoop = () => {
      const yScrollPosition =
        document.getElementsByTagName('html')[0].scrollTop;
      if (bgRef.current) {
        setTranslate(0, yScrollPosition * -0.05, bgRef.current);
      }
      setTimeout(() => {
        requestAnimationFrame(scrollLoop);
      }, 40);
    };
    scrollLoop();
    return () => cancelAnimationFrame(scrollLoop);
  }, []);

  useEffect(() => {
    document.getElementsByTagName('html')[0].classList.add('scrollSnapY');
    const chapterHeight = document.getElementById('chapWrap').offsetHeight;
    bgRef.current.style.height = 4 * chapterHeight + 'px';
    // We want bg height to be at least as tall as chapterHeight.
    // No cost to it being a lot taller.
    // Setting it to an arbitrary 4x the height of chapterHeight here so we don't
    // need to listen for browser width resize to obtain possible resulting changes in chapterHeight
    return () => {
      document.body.classList.remove('hideOverflow');
      document.getElementsByTagName('html')[0].classList.remove('hideOverflow');
    };
  }, []);

  const completeButton = chapnum => {
    if (chapnum === constants.LAST_PART_ONE_CHAP) {
      return 'Complete Part 1';
    } else if (chapnum === constants.LAST_PART_TWO_CHAP) {
      return 'Complete Part 2';
    } else {
      return `Complete Chapter ${chapnum}`;
    }
  };

  return (
    <div
      id="chapWrap"
      className={`chapter chapter-${props.chapterNumber} chapter-${
        props.contents.background
      } ${chapHasLockedLessons ? 'chapter-haslock' : ''}`}
    >
      {/* Hero Section */}
      <Graphic
        ref={heroRef}
        src={images[props.contents.lessons[0].id]}
        srcp={images[props.contents.lessons[0].id + 'p']}
        src2x={images[props.contents.lessons[0].id + '_2x']}
        src2xp={images[props.contents.lessons[0].id + '_2xp']}
        alt={props.contents.lessons[0].title}
        loading="eager"
      />

      <ChapterNav
        chapterNumber={props.chapterNumber}
        isCollapsed={!heroOnScreen}
      />

      {props.contents.lessons.slice(1).map((lesson, i) => (
        <React.Fragment key={lesson.id + i + ''}>
          {lesson.type === 'graphic' && (
            <Graphic
              src={images[lesson.id]}
              srcp={images[lesson.id + 'p']}
              src2x={images[lesson.id + '_2x']}
              src2xp={images[lesson.id + '_2xp']}
              alt={lesson.title}
              ref={null}
              loading={i < 2 ? 'eager' : 'lazy'}
            />
          )}
          {lesson.type === 'vimeo' && (
            <VimeoPlayer
              vimid={lesson.id}
              vimtitle={lesson.title}
              islocked={lesson.locksProgress}
              uponCompletion={updateLockStatus}
            />
          )}
          {lesson.type === 'unity' && (
            <Unity
              id={lesson.id}
              title={lesson.title}
              islocked={lesson.locksProgress}
              uponCompletion={updateLockStatus}
            />
          )}
        </React.Fragment>
      ))}

      <button
        className="chapter__complete"
        onClick={() => {
          doFinishChapter();
        }}
        type="button"
      >
        {completeButton(props.chapterNumber)}
      </button>

      <Overlay
        alertType="completion"
        isOpen={showReward}
        completedItem={`Chapter ${props.chapterNumber}`}
        completionType="chapter"
        continueAction={
          nextStoryView === 'landing' ? goToLanding : advanceToNextChapter
        }
      />

      <Overlay
        alertType="networkError"
        isOpen={showNetworkErrorMessage}
        continueAction={() => doFinishChapter()}
      />

      <FooterBar />
      <div
        id="chapterBg"
        className={`chapter__bg chapter__bg-${props.contents.background}`}
        ref={bgRef}
      ></div>
    </div>
  );
};

export default Chapter;
