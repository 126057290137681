import React from 'react';
import FooterBar from '~app/components/FooterBar';
import { useHistory } from 'react-router-dom';

export const Info = () => {
  const history = useHistory();
  const goBack = () => {
    history.goBack();
  };
  return (
    <div className="info">
      <section>
        <button
          onClick={() => {
            goBack();
          }}
          type="button"
        >
          Go back to the story
        </button>
        <h2>More Information</h2>
        <p>
          <strong>
            This website is collecting data on behalf of the UAB Youth Safety
            Lab at University of Alabama Birmingham (UAB) and will abide by the
            study’s informed consent. You are not authorized to use this website
            unless you have been invited to do so by UAB and have signed the
            study’s informed consent.
          </strong>
        </p>
        <p>
          Children are still developing their cognitive capacity. ShootSafe is
          designed to help children learn safety with firearms, but supervision
          by competent and attentive adults is required to keep children safe.
        </p>
        <p>
          Many children and families enjoy using firearms for hunting and
          shooting, and we recognize the many positives that can come out of
          such activities. We urge parents and other adults to use ShootSafe as
          one way to teach children lifelong lessons about safety with firearms
          so we can all work together to reduce the tragic injuries and deaths
          that occur every year to children from guns.
        </p>
        <p>
          ShootSafe was developed through funding from the Centers for Disease
          Control and Prevention (CDC) of the U.S. Department of Health and
          Human Services (HHS) as part of a financial assistance award totaling
          $1,950,000, with 100 percent funded by CDC/HHS. The contents are those
          of the author(s) and do not necessarily represent the official views
          of, nor an endorsement, by CDC/HHS, or the U.S. Government.{' '}
        </p>
        <p>
          Funding from CDC/HHS was provided to the University of Alabama at
          Birmingham, with Dr. David C. Schwebel serving as Principal
          Investigator. A large team was responsible for creating ShootSafe, and
          we thank and acknowledge their efforts here.
        </p>
        <p>
          Please note that the testimonial stories in ShootSafe were created
          with mostly-amateur actors, some of whom had hunting/shooting
          experience and others of whom did not. All acting was done without
          live ammunition on the sets and with armorers present. On some
          occasions, the actors use poor hunting or shooting form. The lessons
          from the videos remain sound and valuable.
        </p>
        <h3>Acknowledgements</h3>
        <p>
          <strong>Digital Artefacts (website developer): </strong>Joan Severson,
          President; Katelyn Trullinger, Designer; Anna Keil, Writer; Yefei He,
          Game Developer; Sean Divine, Website Developer
        </p>
        <p>
          <strong>Gregory Taylor:</strong> videographer, director, and producer
          of all videos
        </p>
        <p>
          <strong>UAB student, staff and faculty team:</strong> Amanda Benson,
          Marissa Gowey, Anna Johnston, Leann Long, Casie Morgan, Carly Pappas,
          Jay Rembert, Sidney Saint-Hilaire, Yixin Wang
        </p>
        <p>
          <strong>Expert Advisory Board:</strong> Dylan Baumgardner, Marisa
          Futral, Bo Garrett, Stephanie Mallory, Steve McCornack
        </p>
        <p>
          <strong>Data Safety and Monitoring Board:</strong> Tamera
          Coyne-Beasley, Kendall Holman, Zachary Kane Jones
        </p>
        <p>
          <strong>Firearms experts in videos:</strong> Bill Freeman, Marisa
          Futral, Stuart Goldsby, Carter Guest, Will Hendrix, Terrill Hogeland,
          Brandon Holloway, Steve McCornack
        </p>
        <p>
          <strong>Actors in Birthday Party testimonial video:</strong> Ted
          Canter, Andrew Vander Noot, Palmer Vander Noot, Stephanie Vander Noot
        </p>
        <p>
          <strong>Actors in Hide Seek testimonial video:</strong> Rosa Schwebel,
          Olivia Taylor-Sanchez, Paula Taylor-Sanchez
        </p>
        <p>
          <strong>Actors in Hunting testimonial video:</strong> Gabe Beylou,
          Joaquin Crawford-Mendoza, Dyer Guess, Elliot Guess
        </p>
        <p>
          <strong>Actors in Dove Hunt testimonial video:</strong> Will Byrd,
          Cassai Hill, Isaac Hill, Sasha Hill, Andie Linton, Jeff Pugh, Clara
          Rinker, Erika Rinker, Ben Slaughter, Eleanor Slaughter, Lauren
          Slaughter, Sam Slaughter, Chris Zapalowski, Ellis Zapalowski, Liza
          Zapalowski{' '}
        </p>
        <p>
          <strong>Actors in Gun Range testimonial video:</strong> Mason Holmes,
          Christi Toyer, India-Rayne Toyer
        </p>
        <p>
          <strong>Actors in Shooting with a Friend testimonial video:</strong>{' '}
          David Antselevich, Ransom Mallory, Chris Story, Len Ward
        </p>
        <p>
          <strong>Actors in Tripping testimonial video:</strong> Gracie Marble,
          Will Marble, Simon Temple
        </p>
        <p>
          <strong>
            Supportive parents of child actors who did not act themselves:
          </strong>{' '}
          Shaun Crawford, Courtney Holmes, Kathryn McDonald, Helga Mendoza,
          Cheryl Temple, Gale Temple, Laura Zapalowski
        </p>
        <p>
          <strong>Video locations:</strong> Spring Creek Prop Farm, Len Ward and
          Chris Story, Property Managers; Ted and Maggie Canter; David Taylor
          and Lourdes Sanchez-Lopez
        </p>
        <p>
          <strong>Armorers:</strong> Len Ward, Chris Story, Kevin Clark
        </p>
        <p>
          <strong>Testimonial:</strong> John McDonald
        </p>
        <p>
          <strong>Special thanks also to:</strong> Alabama Department of
          Conservation and Natural Resources and Marisa Futral; Jefferson County
          (AL) Sheriff’s Department and Tommie Black; Spring Creek Prop Farm;
          Maggie Canter
        </p>
      </section>

      <FooterBar />
    </div>
  );
};

export default Info;
