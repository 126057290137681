import React, { useContext } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import ProgressPaws from '~app/components/ProgressPaws';
import { chapterContents } from '~app/config/chapterContents';
import { ApiContext } from '~app/api/context';
import { UserProgressContext } from '~app/userprogress/context';

export const HeaderBar = ({ chapterNumber }) => {
  const { authToken } = useContext(ApiContext);
  const userProgressContext = useContext(UserProgressContext);
  const sectionLabel =
    authToken && chapterNumber && userProgressContext.storyView === 'chapter'
      ? `Ch ${chapterNumber}: ${chapterContents[chapterNumber - 1].title}`
      : '';
  const history = useHistory();
  const location = useLocation();
  const goLogin = () => {
    if (location.pathname === '/') {
      window.location.hash = 'loginblock';
    } else {
      history.push('/');
    }
  };

  const logOut = () => {
    window.location.href = process.env.PUBLIC_URL + '/';
  };

  const goMyAccount = () => {
    history.push('/story');
    userProgressContext.setStoryView('account');
    window.scrollTo(0, 0);
  };

  const goToInfo = () => {
    history.push('/info');
    window.scrollTo(0, 0);
  };

  return (
    <header className="headerbar">
      <div className="headerbar__left">
        <img
          src={require('~app/resources/ShootSafe_logo.svg')}
          alt="ShootSafe Story"
          width="240"
          height="41"
        />
        <span>{sectionLabel}</span>
      </div>
      <div className="headerbar__right">
        {authToken ? (
          <>
            <div className="headerbar__progress">
              <ProgressPaws />
            </div>
            <DropdownButton title="Dropdown button">
              <Dropdown.Item
                onClick={() => {
                  goMyAccount();
                }}
              >
                My Account<span></span>
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  goToInfo();
                }}
              >
                More Information<span></span>
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  logOut();
                }}
              >
                Logout
              </Dropdown.Item>
            </DropdownButton>
          </>
        ) : (
          <Link
            className="headerbar__right__login"
            to="#"
            onClick={() => goLogin()}
          >
            User Login
          </Link>
        )}
      </div>
    </header>
  );
};

export default HeaderBar;
