//webp files
export const graphic1_0 = require('~app/resources/hero1.webp');
export const graphic1_1 = require('~app/resources/graphic1.1.webp');
export const graphic1_2 = require('~app/resources/graphic1.2.webp');
export const graphic1_3 = require('~app/resources/graphic1.3.webp');
export const graphic1_4 = require('~app/resources/graphic1.4.webp');
export const graphic1_5 = require('~app/resources/graphic1.5.webp');
export const graphic1_6 = require('~app/resources/graphic1.6.webp');
export const graphic1_7 = require('~app/resources/graphic1.7.webp');

export const graphic2_0 = require('~app/resources/graphic2.0.webp');
export const graphic2_1 = require('~app/resources/graphic2.1.webp');
export const graphic2_2 = require('~app/resources/graphic2.2.webp');
export const graphic2_3 = require('~app/resources/graphic2.3.webp');
export const graphic2_4 = require('~app/resources/graphic2.4.webp');
export const graphic2_5 = require('~app/resources/graphic2.5.webp');
export const graphic2_6 = require('~app/resources/graphic2.6.webp');

export const graphic3_0 = require('~app/resources/graphic3.0.webp');
export const graphic3_1 = require('~app/resources/graphic3.1.webp');
export const graphic3_2 = require('~app/resources/graphic3.2.webp');
export const graphic3_3 = require('~app/resources/graphic3.3.webp');
export const graphic3_4 = require('~app/resources/graphic3.4.webp');
export const graphic3_5 = require('~app/resources/graphic3.5.webp');
export const graphic3_6 = require('~app/resources/graphic3.6.webp');

export const graphic4_0 = require('~app/resources/graphic4.0.webp');
export const graphic4_1 = require('~app/resources/graphic4.1.webp');
export const graphic4_2 = require('~app/resources/graphic4.2.webp');
export const graphic4_3 = require('~app/resources/graphic4.3.webp');
export const graphic4_4 = require('~app/resources/graphic4.4.webp');

export const graphic5_0 = require('~app/resources/graphic5.0.webp');
export const graphic5_1 = require('~app/resources/graphic5.1.webp');
export const graphic5_2 = require('~app/resources/graphic5.2.webp');
export const graphic5_3 = require('~app/resources/graphic5.3.webp');
export const graphic5_4 = require('~app/resources/graphic5.4.webp');
export const graphic5_5 = require('~app/resources/graphic5.5.webp');

export const graphic6_0 = require('~app/resources/graphic6.0.webp');
export const graphic6_1 = require('~app/resources/graphic6.1.webp');
export const graphic6_2 = require('~app/resources/graphic6.2.webp');
export const graphic6_3 = require('~app/resources/graphic6.3.webp');
export const graphic6_4 = require('~app/resources/graphic6.4.webp');
export const graphic6_5 = require('~app/resources/graphic6.5.webp');

export const graphic7_0 = require('~app/resources/graphic7.0.webp');
export const graphic7_1 = require('~app/resources/graphic7.1.webp');
export const graphic7_2 = require('~app/resources/graphic7.2.webp');
export const graphic7_3 = require('~app/resources/graphic7.3.webp');
export const graphic7_4 = require('~app/resources/graphic7.4.webp');
export const graphic7_5 = require('~app/resources/graphic7.5.webp');

export const graphic1_0_2x = require('~app/resources/hero1@2x.webp');
export const graphic1_1_2x = require('~app/resources/graphic1.1@2x.webp');
export const graphic1_2_2x = require('~app/resources/graphic1.2@2x.webp');
export const graphic1_3_2x = require('~app/resources/graphic1.3@2x.webp');
export const graphic1_4_2x = require('~app/resources/graphic1.4@2x.webp');
export const graphic1_5_2x = require('~app/resources/graphic1.5@2x.webp');
export const graphic1_6_2x = require('~app/resources/graphic1.6@2x.webp');
export const graphic1_7_2x = require('~app/resources/graphic1.7@2x.webp');

export const graphic2_0_2x = require('~app/resources/graphic2.0@2x.webp');
export const graphic2_1_2x = require('~app/resources/graphic2.1@2x.webp');
export const graphic2_2_2x = require('~app/resources/graphic2.2@2x.webp');
export const graphic2_3_2x = require('~app/resources/graphic2.3@2x.webp');
export const graphic2_4_2x = require('~app/resources/graphic2.4@2x.webp');
export const graphic2_5_2x = require('~app/resources/graphic2.5@2x.webp');
export const graphic2_6_2x = require('~app/resources/graphic2.6@2x.webp');

export const graphic3_0_2x = require('~app/resources/graphic3.0@2x.webp');
export const graphic3_1_2x = require('~app/resources/graphic3.1@2x.webp');
export const graphic3_2_2x = require('~app/resources/graphic3.2@2x.webp');
export const graphic3_3_2x = require('~app/resources/graphic3.3@2x.webp');
export const graphic3_4_2x = require('~app/resources/graphic3.4@2x.webp');
export const graphic3_5_2x = require('~app/resources/graphic3.5@2x.webp');
export const graphic3_6_2x = require('~app/resources/graphic3.6@2x.webp');

export const graphic4_0_2x = require('~app/resources/graphic4.0@2x.webp');
export const graphic4_1_2x = require('~app/resources/graphic4.1@2x.webp');
export const graphic4_2_2x = require('~app/resources/graphic4.2@2x.webp');
export const graphic4_3_2x = require('~app/resources/graphic4.3@2x.webp');
export const graphic4_4_2x = require('~app/resources/graphic4.4@2x.webp');

export const graphic5_0_2x = require('~app/resources/graphic5.0@2x.webp');
export const graphic5_1_2x = require('~app/resources/graphic5.1@2x.webp');
export const graphic5_2_2x = require('~app/resources/graphic5.2@2x.webp');
export const graphic5_3_2x = require('~app/resources/graphic5.3@2x.webp');
export const graphic5_4_2x = require('~app/resources/graphic5.4@2x.webp');
export const graphic5_5_2x = require('~app/resources/graphic5.5@2x.webp');

export const graphic6_0_2x = require('~app/resources/graphic6.0@2x.webp');
export const graphic6_1_2x = require('~app/resources/graphic6.1@2x.webp');
export const graphic6_2_2x = require('~app/resources/graphic6.2@2x.webp');
export const graphic6_3_2x = require('~app/resources/graphic6.3@2x.webp');
export const graphic6_4_2x = require('~app/resources/graphic6.4@2x.webp');
export const graphic6_5_2x = require('~app/resources/graphic6.5@2x.webp');

export const graphic7_0_2x = require('~app/resources/graphic7.0@2x.webp');
export const graphic7_1_2x = require('~app/resources/graphic7.1@2x.webp');
export const graphic7_2_2x = require('~app/resources/graphic7.2@2x.webp');
export const graphic7_3_2x = require('~app/resources/graphic7.3@2x.webp');
export const graphic7_4_2x = require('~app/resources/graphic7.4@2x.webp');
export const graphic7_5_2x = require('~app/resources/graphic7.5@2x.webp');

//png files
export const graphic1_0p = require('~app/resources/hero1.png');
export const graphic1_1p = require('~app/resources/graphic1.1.png');
export const graphic1_2p = require('~app/resources/graphic1.2.png');
export const graphic1_3p = require('~app/resources/graphic1.3.png');
export const graphic1_4p = require('~app/resources/graphic1.4.png');
export const graphic1_5p = require('~app/resources/graphic1.5.png');
export const graphic1_6p = require('~app/resources/graphic1.6.png');
export const graphic1_7p = require('~app/resources/graphic1.7.png');

export const graphic2_0p = require('~app/resources/graphic2.0.png');
export const graphic2_1p = require('~app/resources/graphic2.1.png');
export const graphic2_2p = require('~app/resources/graphic2.2.png');
export const graphic2_3p = require('~app/resources/graphic2.3.png');
export const graphic2_4p = require('~app/resources/graphic2.4.png');
export const graphic2_5p = require('~app/resources/graphic2.5.png');
export const graphic2_6p = require('~app/resources/graphic2.6.png');

export const graphic3_0p = require('~app/resources/graphic3.0.png');
export const graphic3_1p = require('~app/resources/graphic3.1.png');
export const graphic3_2p = require('~app/resources/graphic3.2.png');
export const graphic3_3p = require('~app/resources/graphic3.3.png');
export const graphic3_4p = require('~app/resources/graphic3.4.png');
export const graphic3_5p = require('~app/resources/graphic3.5.png');
export const graphic3_6p = require('~app/resources/graphic3.6.png');

export const graphic4_0p = require('~app/resources/graphic4.0.png');
export const graphic4_1p = require('~app/resources/graphic4.1.png');
export const graphic4_2p = require('~app/resources/graphic4.2.png');
export const graphic4_3p = require('~app/resources/graphic4.3.png');
export const graphic4_4p = require('~app/resources/graphic4.4.png');

export const graphic5_0p = require('~app/resources/graphic5.0.png');
export const graphic5_1p = require('~app/resources/graphic5.1.png');
export const graphic5_2p = require('~app/resources/graphic5.2.png');
export const graphic5_3p = require('~app/resources/graphic5.3.png');
export const graphic5_4p = require('~app/resources/graphic5.4.png');
export const graphic5_5p = require('~app/resources/graphic5.5.png');

export const graphic6_0p = require('~app/resources/graphic6.0.png');
export const graphic6_1p = require('~app/resources/graphic6.1.png');
export const graphic6_2p = require('~app/resources/graphic6.2.png');
export const graphic6_3p = require('~app/resources/graphic6.3.png');
export const graphic6_4p = require('~app/resources/graphic6.4.png');
export const graphic6_5p = require('~app/resources/graphic6.5.png');

export const graphic7_0p = require('~app/resources/graphic7.0.png');
export const graphic7_1p = require('~app/resources/graphic7.1.png');
export const graphic7_2p = require('~app/resources/graphic7.2.png');
export const graphic7_3p = require('~app/resources/graphic7.3.png');
export const graphic7_4p = require('~app/resources/graphic7.4.png');
export const graphic7_5p = require('~app/resources/graphic7.5.png');

export const graphic1_0_2xp = require('~app/resources/hero1@2x.png');
export const graphic1_1_2xp = require('~app/resources/graphic1.1@2x.png');
export const graphic1_2_2xp = require('~app/resources/graphic1.2@2x.png');
export const graphic1_3_2xp = require('~app/resources/graphic1.3@2x.png');
export const graphic1_4_2xp = require('~app/resources/graphic1.4@2x.png');
export const graphic1_5_2xp = require('~app/resources/graphic1.5@2x.png');
export const graphic1_6_2xp = require('~app/resources/graphic1.6@2x.png');
export const graphic1_7_2xp = require('~app/resources/graphic1.7@2x.png');

export const graphic2_0_2xp = require('~app/resources/graphic2.0@2x.png');
export const graphic2_1_2xp = require('~app/resources/graphic2.1@2x.png');
export const graphic2_2_2xp = require('~app/resources/graphic2.2@2x.png');
export const graphic2_3_2xp = require('~app/resources/graphic2.3@2x.png');
export const graphic2_4_2xp = require('~app/resources/graphic2.4@2x.png');
export const graphic2_5_2xp = require('~app/resources/graphic2.5@2x.png');
export const graphic2_6_2xp = require('~app/resources/graphic2.6@2x.png');

export const graphic3_0_2xp = require('~app/resources/graphic3.0@2x.png');
export const graphic3_1_2xp = require('~app/resources/graphic3.1@2x.png');
export const graphic3_2_2xp = require('~app/resources/graphic3.2@2x.png');
export const graphic3_3_2xp = require('~app/resources/graphic3.3@2x.png');
export const graphic3_4_2xp = require('~app/resources/graphic3.4@2x.png');
export const graphic3_5_2xp = require('~app/resources/graphic3.5@2x.png');
export const graphic3_6_2xp = require('~app/resources/graphic3.6@2x.png');

export const graphic4_0_2xp = require('~app/resources/graphic4.0@2x.png');
export const graphic4_1_2xp = require('~app/resources/graphic4.1@2x.png');
export const graphic4_2_2xp = require('~app/resources/graphic4.2@2x.png');
export const graphic4_3_2xp = require('~app/resources/graphic4.3@2x.png');
export const graphic4_4_2xp = require('~app/resources/graphic4.4@2x.png');

export const graphic5_0_2xp = require('~app/resources/graphic5.0@2x.png');
export const graphic5_1_2xp = require('~app/resources/graphic5.1@2x.png');
export const graphic5_2_2xp = require('~app/resources/graphic5.2@2x.png');
export const graphic5_3_2xp = require('~app/resources/graphic5.3@2x.png');
export const graphic5_4_2xp = require('~app/resources/graphic5.4@2x.png');
export const graphic5_5_2xp = require('~app/resources/graphic5.5@2x.png');

export const graphic6_0_2xp = require('~app/resources/graphic6.0@2x.png');
export const graphic6_1_2xp = require('~app/resources/graphic6.1@2x.png');
export const graphic6_2_2xp = require('~app/resources/graphic6.2@2x.png');
export const graphic6_3_2xp = require('~app/resources/graphic6.3@2x.png');
export const graphic6_4_2xp = require('~app/resources/graphic6.4@2x.png');
export const graphic6_5_2xp = require('~app/resources/graphic6.5@2x.png');

export const graphic7_0_2xp = require('~app/resources/graphic7.0@2x.png');
export const graphic7_1_2xp = require('~app/resources/graphic7.1@2x.png');
export const graphic7_2_2xp = require('~app/resources/graphic7.2@2x.png');
export const graphic7_3_2xp = require('~app/resources/graphic7.3@2x.png');
export const graphic7_4_2xp = require('~app/resources/graphic7.4@2x.png');
export const graphic7_5_2xp = require('~app/resources/graphic7.5@2x.png');

export const graphicPawAward = require('~app/resources/graphicPawAward.png');

//pdf files
export const chap1Cert = require('~app/resources/certs/Chapter1Certificate.pdf');
export const chap2Cert = require('~app/resources/certs/Chapter2Certificate.pdf');
export const chap3Cert = require('~app/resources/certs/Chapter3Certificate.pdf');
export const chap4Cert = require('~app/resources/certs/Chapter4Certificate.pdf');
export const chap5Cert = require('~app/resources/certs/Chapter5Certificate.pdf');
export const chap6Cert = require('~app/resources/certs/Chapter6Certificate.pdf');
export const chap7Cert = require('~app/resources/certs/Chapter7Certificate.pdf');
