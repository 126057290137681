import React from 'react';

export const Paw = () => {

        return (
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g>
                    <path d="M0.974574 5.1137C0.971331 5.0056 0.97552 4.90433 0.985011 4.79557C1.08423 3.82324 1.68034 3.17181 2.51548 2.96281C3.2583 2.78022 4.00453 3.00888 4.71162 3.44888C4.97386 3.61583 5.24459 3.79679 5.46441 4.02367C6.22476 4.82183 7.10174 5.41188 7.98402 5.99444C8.25475 6.17539 8.52019 6.36384 8.77608 6.57443C10.0566 7.63453 10.2996 9.44098 9.30684 10.5748C9.02683 10.8924 8.67885 11.1412 8.39141 11.452C7.91092 11.9747 7.41557 12.4839 6.98818 13.0616C6.45897 13.7746 5.87558 14.3821 5.0086 14.5927C4.12252 14.8043 3.08642 14.3524 2.48205 13.4812C1.68117 12.3243 2.10496 10.9888 2.12762 9.76052C2.13373 9.28382 2.1154 8.77228 1.97611 8.31028C1.68475 7.30031 1.00488 6.18041 0.974574 5.1137Z" fill="#D7D7D7" />
                    <path d="M16.1998 11.1719C16.1901 11.5032 16.0733 11.8253 15.847 12.1235C15.3453 12.7886 14.5184 12.963 13.7056 12.8352C12.9322 12.7055 12.2232 12.3887 11.63 11.7425C10.7587 10.787 10.8193 9.56357 11.7651 8.94939C12.8673 8.2317 14.7184 8.62275 15.6674 9.78749C16.0319 10.2321 16.2159 10.7079 16.1998 11.1719Z" fill="#D7D7D7" />
                    <path d="M10.6853 16.6433C10.0079 16.5973 9.29982 16.2603 8.81346 15.6925C8.55015 15.384 8.29115 15.0168 8.16264 14.643C7.82501 13.6653 8.26601 12.8381 9.15209 12.654C10.3317 12.4111 11.6728 13.1109 12.2628 14.2806C12.7396 15.2219 12.4422 16.1296 11.6279 16.4856C11.3388 16.6173 11.0104 16.6633 10.6853 16.6433Z" fill="#D7D7D7" />
                    <path d="M9.58743 0.266268C9.82065 0.283712 10.0463 0.338184 10.2937 0.450169C11.0589 0.806944 11.4495 1.60781 11.2713 2.43763C10.9908 3.77095 9.45582 4.60386 8.20775 4.10311C7.17768 3.69386 6.76726 2.49829 7.30941 1.50339C7.771 0.673811 8.64582 0.18228 9.58743 0.266268Z" fill="#D7D7D7" />
                    <path d="M13.2272 7.51361C12.7049 7.5181 12.2035 7.4406 11.7172 7.15631C10.737 6.58078 10.4178 5.47133 10.9614 4.55344C11.6407 3.43735 13.2159 2.99673 14.45 3.58877C15.7478 4.20702 16.0354 5.72273 15.0463 6.71478C14.5462 7.21845 13.919 7.44899 13.2272 7.51361Z" fill="#D7D7D7" />
                </g>
            </svg>
        );
   
}

export default Paw;

