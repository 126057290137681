import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';

import App from '~app/components/App';
import { API_URL } from '~app/env';
import ApiProvider from '~app/api/context';
import UserProgressProvider from '~app/userprogress/context';

import '~app/styles/index.scss';

const base = process.env.PUBLIC_URL;

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter basename={base}>
      <ApiProvider url={API_URL}>
        <UserProgressProvider>
          <App />
        </UserProgressProvider>
      </ApiProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root'),
);
