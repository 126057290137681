import React, { useContext, useState } from 'react';
import Vimeo from '@u-wave/react-vimeo';
import { useMutation } from 'react-query';
import { apiPost } from '~app/api/hooks';
import { ApiContext } from '~app/api/context';
import { UserProgressContext } from '~app/userprogress/context';
import FooterBar from '~app/components/FooterBar';
import { HTTPNotFound } from '~app/api/errors';
import Spinner from 'react-bootstrap/Spinner';
import { graphic1_0 } from '~app/config/images';

export const Login = () => {
  const minPinCharsRequiredToSubmit = 5;
  const apiContext = useContext(ApiContext);
  const userProgressContext = useContext(UserProgressContext);
  const [submitPinDisabled, setSubmitPinDisabled] = useState(true);
  const [invalidPinError, setInvalidPinError] = useState(false);
  const [hasNetworkError, setHasNetworkError] = useState(false);
  const [pincode, setPincode] = useState('');
  const [loading, setLoading] = useState(false);

  const [doMutation] = useMutation(
    body => apiPost(apiContext.url + '/login', body),
    {
      onSuccess: data => {
        setLoading(false);
        apiContext.setAuthToken(data.json.token);
        userProgressContext.setFurthestPart(data.json.part_number);
        const furthestChapCompleted = data.json.chapters_completed.length
          ? Math.max(...data.json.chapters_completed)
          : 0;

        const currentChapter = furthestChapCompleted;
        //currentChapter, furthestChapter, and furthestChapterCompleted are
        //initially all the same on login.
        //But user can select any chapter at or below furthestChapter as currentChapter
        //and furthestChapter will sometimes be one chapter ahead of furthestChapterCompleted
        userProgressContext.setFurthestChapter(currentChapter);
        userProgressContext.setFurthestChapterCompleted(currentChapter);
        userProgressContext.setCurrentChapter(currentChapter);
        userProgressContext.setPassedVideos(
          new Set(data.json.videos_completed),
        );
        userProgressContext.setPassedGames(new Set(data.json.games_completed));
        userProgressContext.setActivityCompletionCount(
          data.json.videos_completed.length + data.json.games_completed.length,
        );
      },
      onError: error => {
        if (error instanceof HTTPNotFound) {
          setInvalidPinError(true);
          setPincode('');
        } else {
          setHasNetworkError(true);
        }
        setLoading(false);
      },
    },
  );

  const submitPin = () => {
    setHasNetworkError(false);
    setLoading(true);
    doMutation({
      pincode: pincode,
    });
  };
  return (
    <div className="login">
      <div className="login__hero">
        <section>
          <h1>
            Teaching
            <br />
            Firearm Safety
          </h1>
          Learn shooting and hunting safety
          <br />
          through a fun interactive story.
        </section>
        <section>
          <Vimeo video={'//vimeo.com/540373829/78da8ed1e7'} />
        </section>
      </div>
      <div id="loginblock" className="login__form">
        <h3>User Login</h3>
        Use the Pincode the researcher gave you to log in.
        <fieldset>
          <label htmlFor="pincode">Enter Pincode</label>
          <input
            autoComplete="off"
            type="text"
            id="pincode"
            name="pincode"
            value={pincode}
            placeholder="Type Pincode"
            disabled={loading}
            onChange={e => {
              setPincode(e.target.value);
              setSubmitPinDisabled(
                e.target.value.length < minPinCharsRequiredToSubmit,
              );
              setInvalidPinError(false);
            }}
          />
          <label>
            {invalidPinError && <output>Pincode is incorrect</output>}
            {hasNetworkError && (
              <output className="networkError">
                Network error. Please try again later.
              </output>
            )}
            {loading && <Spinner animation="border" variant="primary" />}
          </label>
        </fieldset>
        <fieldset>
          <input
            type="submit"
            value="Login"
            onClick={submitPin}
            disabled={submitPinDisabled}
          />
        </fieldset>
      </div>
      <FooterBar />
      <link rel="prefetch" href={graphic1_0}></link>
    </div>
  );
};

export default Login;
