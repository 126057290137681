import React, { useState } from 'react';
import { chapterContents } from '~app/config/chapterContents';

export const UserProgressContext = React.createContext();

function UserProgressProvider({ children }) {
  const chapterCount = chapterContents.length;
  const [passedVideos, setPassedVideos] = useState(new Set());
  const [passedGames, setPassedGames] = useState(new Set());
  const [furthestPart, setFurthestPart] = useState(1);
  const [currentChapter, setCurrentChapter] = useState(0);
  const [furthestChapter, setFurthestChapter] = useState(1);
  const [furthestChapterCompleted, setFurthestChapterCompleted] = useState(0);
  const [storyView, setStoryView] = useState('landing');
  const [landingState, setLandingState] = useState('initial');
  const [activityCompletionCount, setActivityCompletionCount] = useState(0);
  const [totalChapterCount] = useState(chapterCount);
  return (
    <UserProgressContext.Provider
      value={{
        passedVideos,
        setPassedVideos,
        passedGames,
        setPassedGames,
        furthestPart,
        setFurthestPart,
        currentChapter,
        setCurrentChapter,
        furthestChapter,
        setFurthestChapter,
        furthestChapterCompleted,
        setFurthestChapterCompleted,
        totalChapterCount,
        storyView,
        setStoryView,
        landingState,
        setLandingState,
        activityCompletionCount,
        setActivityCompletionCount,
      }}
    >
      {children}
    </UserProgressContext.Provider>
  );
}

export default UserProgressProvider;
