import React, { useContext } from 'react';
import Paw from '~app/components/Paw';
import { UserProgressContext } from '~app/userprogress/context';

export const ProgressPaws = () => {
  const pawCount = 24;
  const pawClasses = [];
  const userProgressContext = useContext(UserProgressContext);
  for (let i = 0; i < pawCount; i++) {
    pawClasses.push({
      key: i,
      class: `paw-${i}${
        userProgressContext.activityCompletionCount > i ? ' paw-earned' : ''
      }`,
    });
  }

  return (
    <ul className="pawPrints">
      {pawClasses.map(paw => {
        return (
          <li className={paw.class} key={paw.key}>
            <Paw />
          </li>
        );
      })}
    </ul>
  );
};

export default ProgressPaws;
