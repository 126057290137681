import React, { useState } from 'react';
import { ReactQueryConfigProvider } from 'react-query';

const queryConfig = {
  retry: false,
};

const defaultAppData = {
  routes: {},
  services: [],
};

export const ApiContext = React.createContext();
export const ApiErrorContext = React.createContext();
export const NotFoundContext = React.createContext();

function ApiProvider({ children, url }) {
  const [apiError, setApiError] = useState(false);

  const [appData] = useState(defaultAppData);

  const [authToken, setAuthToken] = useState(null);

  return (
    <ReactQueryConfigProvider config={queryConfig}>
      <ApiContext.Provider value={{ url, appData, authToken, setAuthToken }}>
        <ApiErrorContext.Provider value={{ apiError, setApiError }}>
          {children}
        </ApiErrorContext.Provider>
      </ApiContext.Provider>
    </ReactQueryConfigProvider>
  );
}

export default ApiProvider;
