import React, { useState, useContext } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import * as images from '~app/config/images';
import { UserProgressContext } from '~app/userprogress/context';
import constants from '~app/utils/constants';
import Spinner from 'react-bootstrap/Spinner';

export const Overlay = ({
  alertType,
  isOpen,
  completedItem,
  completionType,
  continueAction,
}) => {
  const [show, setShow] = useState(true);
  const [showRetrying, setShowRetrying] = useState(false);
  const handleClose = () => setShow(false);
  const userProgressContext = useContext(UserProgressContext);
  const delayedTopScroll = () => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 300);
    //Closing the react-bootstrap modal (via setShow(false) )
    //triggers a mechanism in react-bootstrap that forces the vertical scroll position
    //to stay where the modal was last positioned. That mechanism takes over 100ms to run.
    //In this case, we don't want the scroll position to return to the location of the
    //modal we're closing as we're redirecting to a new page and want to start at the top.
    //So triggering window.scrollTo(0, 0) only after the react-bootstrap mechanism is allowed to run
    //prevents us from scrolling back to where we were before. Hacky, I know, but best solution I
    //uncovered in reasonable time frame. I also tried using onExited method of modal to no avail.
    //Can revisit if needed.
  };
  const goAccount = () => {
    continueAction();
    userProgressContext.setStoryView('account');
    delayedTopScroll();
  };

  const handleNext = () => {
    continueAction();
    delayedTopScroll();
  };

  const retryConnection = () => {
    setShowRetrying(true);
    setTimeout(() => {
      setShowRetrying(false);
      continueAction();
    }, 1000); //Show user visual that server connection is retrying, otherwise might happen too fast to register
  };

  return (
    <>
      {alertType === 'completion' && (
        <Modal
          show={show && isOpen}
          className={
            completionType === 'chapter' ? 'modal-chapterComplete' : ''
          }
          backdrop="static"
        >
          <Modal.Header>
            <Modal.Title>Congrats!</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h3>You completed {completedItem}.</h3>
            {completionType === 'lesson' && (
              <>
                <h4>Here is a paw print to mark your progress.</h4>
                <img src={images['graphicPawAward']} alt="Paw Award" />
              </>
            )}
            {completionType === 'chapter' && (
              <span>
                Go to My Account to print your chapter certificate or continue
                to
                {(userProgressContext.furthestPart > 1 &&
                  userProgressContext.currentChapter <
                    constants.LAST_PART_TWO_CHAP) ||
                (userProgressContext.furthestPart < 2 &&
                  userProgressContext.currentChapter <
                    constants.LAST_PART_ONE_CHAP)
                  ? ` Chapter ${+userProgressContext.currentChapter + 1}.`
                  : ' revisit chapters.'}
              </span>
            )}
          </Modal.Body>
          <Modal.Footer>
            {completionType === 'lesson' && (
              <Button variant="primary" onClick={handleClose} type="button">
                Continue
              </Button>
            )}
            {completionType === 'chapter' && (
              <>
                <Button variant="secondary" onClick={goAccount} type="button">
                  My Account
                </Button>
                <Button variant="primary" onClick={handleNext} type="button">
                  Continue
                </Button>
              </>
            )}
          </Modal.Footer>
        </Modal>
      )}
      {alertType === 'networkError' && (
        <Modal
          show={show && isOpen}
          className="modal-networkError"
          backdrop="static"
        >
          <Modal.Header>
            <Modal.Title>Connection Error</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h3>
              There are network connection problems and you cannot continue
              until they are solved. Please ask the researcher to check the
              connection.
            </h3>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={retryConnection} type="button">
              Retry Connection
              {showRetrying && <Spinner animation="border" variant="light" />}
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export default Overlay;
