import React, {useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {NotFoundContext} from '~app/api/context';

function NotFoundProvider({children, NotFound}) {
  const [notFound, setNotFound] = useState(null);
  const location = useLocation();

  useEffect(
    () => setNotFound(null),
    [location, setNotFound],
  );

  return (
    <NotFoundContext.Provider value={{setNotFound}}>
      {notFound
        ? <NotFound/>
        : {...children}
      }
    </NotFoundContext.Provider>
  );
}

export default NotFoundProvider;
