import React from 'react';

export const Graphic = React.forwardRef((props, ref) => {
  return (
    <section ref={ref} className="panel">
      <picture>
        <source
          srcSet={(`${props.src} 1x`, `${props.src2x} 2x`)}
          type="image/webp"
        ></source>
        <img
          srcSet={`${props.src2xp} 2x`}
          src={props.srcpp}
          alt={props.alt}
          loading={props.loading}
          width="400" //this is for faster page rendering and will get overriden by css
          height="206" //this is for faster page rendering and will get overriden by css
        />
      </picture>
    </section>
  );
});

export default Graphic;
