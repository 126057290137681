import { HTTPError } from './errors';
import { objectToSearchParams } from './utils';

export function apiFetch(url, opts = {}, nextUrl) {
  const controller = new AbortController();
  const signal = controller.signal;
  const fn = async () => {
    const response = await fetch(nextUrl ?? url, {
      signal,
      method: 'get',
      credentials:
        process.env.NODE_ENV === 'development' ? 'include' : 'same-origin',
      ...opts,
    });
    if (!response.ok) {
      let json = null;
      try {
        json = await response.json();
      } catch (e) {}
      throw HTTPError.fromResponse(response, json);
    }
    if (response.status === 204) {
      //.json() method cannot be applied to a "204 OK, No Content" response
      //nor is it necessary
      return {};
    }
    const json = await response.json();
    return { json };
  };
  const promise = fn();
  promise.cancel = () => controller.abort();
  return promise;
}

export const apiPost = (url, json, options = {}) => {
  const { queryParams } = options;
  const fetchOptions = {
    method: 'POST',
    body: JSON.stringify(json),
    ...options.fetchOptions,
  };
  fetchOptions.headers = {
    ...fetchOptions.headers,
    'Content-Type': 'application/json',
  };
  if (queryParams !== null) {
    const qstr = objectToSearchParams(queryParams).toString();
    url = `${url}?${qstr}`;
  }
  return apiFetch(url, fetchOptions);
};
