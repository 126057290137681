export const chapterContents = [
  {
    chapnum: 1,
    title: 'Safe preparation',
    background: 'earprotection', //choose one of the file names located in /resources/bgs/
    //(excluding extension) and make sure the file is referenced in /styles/components/chapter.scss

    //NOTE: defining lesson here as any informational content within a chapter
    //This includes static infographics as well as activities such as videos and games
    lessons: [
      {
        type: 'graphic',
        id: 'graphic1_0', //must be defined in /config/images.js
        locksProgress: false, // when true, lesson must get marked as completed before user can continue further
        title: 'Hero', //first graphic in each chapter will be the hero graphic
      },
      {
        type: 'graphic',
        id: 'graphic1_1',
        locksProgress: false,
        title: 'Comic 1.1',
      },
      {
        type: 'vimeo',
        id: '540373829/78da8ed1e7',
        locksProgress: true,
        title: 'Intro',
      },
      {
        type: 'graphic',
        id: 'graphic1_2',
        locksProgress: false,
        title: 'Comic 1.2',
      },
      {
        type: 'vimeo',
        id: '540374028/5d89896578',
        locksProgress: true,
        title: '3 Rules',
      },
      {
        type: 'graphic',
        id: 'graphic1_3',
        locksProgress: false,
        title: 'Comic 1.3',
      },
      {
        type: 'graphic',
        id: 'graphic1_4',
        locksProgress: false,
        title: 'Comic 1.4',
      },
      {
        type: 'unity',
        id: 'DinosHunt',
        locksProgress: true,
        title: "Dino's Hunt",
      },
      {
        type: 'graphic',
        id: 'graphic1_5',
        locksProgress: false,
        title: 'Comic 1.5',
      },
      {
        type: 'vimeo',
        id: '540366286/d9b3b0f87b',
        locksProgress: true,
        title: 'Birthday Party',
      },
      {
        type: 'graphic',
        id: 'graphic1_6',
        locksProgress: false,
        title: 'Comic 1.6',
      },
      {
        type: 'graphic',
        id: 'graphic1_7',
        locksProgress: false,
        title: 'Comic 1.7',
      },
    ],
  },
  {
    chapnum: 2,
    title: 'Safe transport',
    background: 'car',
    lessons: [
      {
        type: 'graphic',
        id: 'graphic2_0',
        locksProgress: false,
        title: 'Hero',
      },
      {
        type: 'graphic',
        id: 'graphic2_1',
        locksProgress: false,
        title: 'Comic 2.1',
      },
      {
        type: 'vimeo',
        id: '540369442/50d5fd0395',
        locksProgress: true,
        title: 'Safe Transport',
      },
      {
        type: 'graphic',
        id: 'graphic2_2',
        locksProgress: false,
        title: 'Comic 2.2',
      },
      {
        type: 'vimeo',
        id: '540373698/abedb9884f',
        locksProgress: true,
        title: 'Not a Toy',
      },
      {
        type: 'graphic',
        id: 'graphic2_3',
        locksProgress: false,
        title: 'Comic 2.3',
      },
      {
        type: 'graphic',
        id: 'graphic2_4',
        locksProgress: false,
        title: 'Comic 2.4',
      },
      {
        type: 'unity',
        id: 'SafetyTrivia',
        locksProgress: true,
        title: 'Safety Trivia',
      },
      {
        type: 'graphic',
        id: 'graphic2_5',
        locksProgress: false,
        title: 'Comic 2.5',
      },
      {
        type: 'vimeo',
        id: '540371068/beb4d9f2c4',
        locksProgress: true,
        title: 'Hide and Seek',
      },
      {
        type: 'graphic',
        id: 'graphic2_6',
        locksProgress: false,
        title: 'Comic 2.6',
      },
    ],
  },
  {
    chapnum: 3,
    title: 'Safety on foot',
    background: 'pawprint',
    lessons: [
      {
        type: 'graphic',
        id: 'graphic3_0',
        locksProgress: false,
        title: 'Hero',
      },
      {
        type: 'graphic',
        id: 'graphic3_1',
        locksProgress: false,
        title: 'Comic 3.1',
      },
      {
        type: 'vimeo',
        id: '540368492/7091cb2aae',
        locksProgress: true,
        title: 'Crossing a Fence',
      },
      {
        type: 'graphic',
        id: 'graphic3_2',
        locksProgress: false,
        title: 'Comic 3.2',
      },
      {
        type: 'graphic',
        id: 'graphic3_3',
        locksProgress: false,
        title: 'Comic 3.3',
      },
      {
        type: 'unity',
        id: 'ExpectTheUnexpected',
        locksProgress: true,
        title: 'Expect the Unexpected',
      },
      {
        type: 'graphic',
        id: 'graphic3_4',
        locksProgress: false,
        title: 'Comic 3.4',
      },
      {
        type: 'graphic',
        id: 'graphic3_5',
        locksProgress: false,
        title: 'Comic 3.5',
      },
      {
        type: 'vimeo',
        id: '540367922/268193e9b4',
        locksProgress: true,
        title: 'Hunting',
      },
      {
        type: 'graphic',
        id: 'graphic3_6',
        locksProgress: false,
        title: 'Comic 3.6',
      },
    ],
  },
  {
    chapnum: 4,
    title: 'Safety while hunting',
    background: 'snowflake',
    lessons: [
      {
        type: 'graphic',
        id: 'graphic4_0',
        locksProgress: false,
        title: 'Comic 4.0',
      },
      {
        type: 'graphic',
        id: 'graphic4_1',
        locksProgress: false,
        title: 'Comic 4.1',
      },
      {
        type: 'vimeo',
        id: '540375676/70b183f42f',
        locksProgress: true,
        title: 'Wear & Hold',
      },
      {
        type: 'graphic',
        id: 'graphic4_2',
        locksProgress: false,
        title: 'Comic 4.2',
      },
      {
        type: 'unity',
        id: 'GraysJourney',
        locksProgress: true,
        title: "Gray's Journey",
      },
      {
        type: 'graphic',
        id: 'graphic4_3',
        locksProgress: false,
        title: 'Comic 4.3',
      },
      {
        type: 'vimeo',
        id: '540367722/3e96d3dd68',
        locksProgress: true,
        title: 'Dove Hunt',
      },
      {
        type: 'graphic',
        id: 'graphic4_4',
        locksProgress: false,
        title: 'Comic 4.4',
      },
    ],
  },
  {
    chapnum: 5,
    title: 'Safety at the range',
    background: 'flag',
    lessons: [
      {
        type: 'graphic',
        id: 'graphic5_0',
        locksProgress: false,
        title: 'Comic 5.0',
      },
      {
        type: 'graphic',
        id: 'graphic5_1',
        locksProgress: false,
        title: 'Comic 5.1',
      },
      {
        type: 'vimeo',
        id: '540364496/493a0b398d',
        locksProgress: true,
        title: 'Range Safety',
      },
      {
        type: 'graphic',
        id: 'graphic5_2',
        locksProgress: false,
        title: 'Comic 5.2',
      },
      {
        type: 'graphic',
        id: 'graphic5_3',
        locksProgress: false,
        title: 'Comic 5.3',
      },
      {
        type: 'unity',
        id: 'CheckForBabyDeer',
        locksProgress: true,
        title: 'Check For Baby Deer',
      },
      {
        type: 'graphic',
        id: 'graphic5_4',
        locksProgress: false,
        title: 'Comic 5.4',
      },
      {
        type: 'vimeo',
        id: '540365232/b91142fd78',
        locksProgress: true,
        title: 'Gun Range',
      },
      {
        type: 'graphic',
        id: 'graphic5_5',
        locksProgress: false,
        title: 'Comic 5.5',
      },
    ],
  },
  {
    chapnum: 6,
    title: 'Safe climbing',
    background: 'tree',
    lessons: [
      {
        type: 'graphic',
        id: 'graphic6_0',
        locksProgress: false,
        title: 'Comic 6.0',
      },
      {
        type: 'graphic',
        id: 'graphic6_1',
        locksProgress: false,
        title: 'Comic 6.1',
      },
      {
        type: 'vimeo',
        id: '540374499/907a9d8d85',
        locksProgress: true,
        title: 'Tree Stand Safety',
      },
      {
        type: 'graphic',
        id: 'graphic6_2',
        locksProgress: false,
        title: 'Comic 6.2',
      },
      {
        type: 'graphic',
        id: 'graphic6_3',
        locksProgress: false,
        title: 'Comic 6.3',
      },
      {
        type: 'unity',
        id: 'WhenToShoot',
        locksProgress: true,
        title: 'When to Shoot',
      },
      {
        type: 'graphic',
        id: 'graphic6_4',
        locksProgress: false,
        title: 'Comic 6.4',
      },
      {
        type: 'vimeo',
        id: '540371434/b1feaa7336',
        locksProgress: true,
        title: 'Shooting with a Friend',
      },
      {
        type: 'graphic',
        id: 'graphic6_5',
        locksProgress: false,
        title: 'Comic 6.5',
      },
    ],
  },
  {
    chapnum: 7,
    title: 'Safe storage',
    background: 'crosshairs',
    lessons: [
      {
        type: 'graphic',
        id: 'graphic7_0',
        locksProgress: false,
        title: 'Comic 7.0',
      },
      {
        type: 'graphic',
        id: 'graphic7_1',
        locksProgress: false,
        title: 'Comic 7.1',
      },
      {
        type: 'vimeo',
        id: '540366955/f899f035f4',
        locksProgress: true,
        title: 'Cleaning & Storage',
      },
      {
        type: 'graphic',
        id: 'graphic7_2',
        locksProgress: false,
        title: 'Comic 7.2',
      },
      {
        type: 'unity',
        id: 'WhatIsWrong',
        locksProgress: true,
        title: 'What Is Wrong',
      },
      {
        type: 'graphic',
        id: 'graphic7_3',
        locksProgress: false,
        title: 'Comic 7.3',
      },
      {
        type: 'vimeo',
        id: '540375001/f871425c7b',
        locksProgress: true,
        title: 'Tripping',
      },
      {
        type: 'graphic',
        id: 'graphic7_4',
        locksProgress: false,
        title: 'Comic 7.4',
      },
      {
        type: 'vimeo',
        id: '540372341/594f89d924',
        locksProgress: true,
        title: 'Outro',
      },

      {
        type: 'graphic',
        id: 'graphic7_5',
        locksProgress: false,
        title: 'Comic 7.5',
      },
    ],
  },
];
