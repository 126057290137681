import React, { useContext } from 'react';
import { Route, Switch } from 'react-router-dom';
import { ApiErrorContext } from '~app/api/context';
import { HTTPNotFound, HTTPUnauthorized } from '~app/api/errors';
import HeaderBar from '~app/components/HeaderBar';

import NotFound from '~app/components/NotFound';
import NotFoundProvider from '~app/components/NotFoundProvider';
import QueryError from '~app/components/QueryError';
import Story from '~app/pages/Story';
import Info from '~app/pages/Info';
import { UserProgressContext } from '~app/userprogress/context';

function App() {
  const { apiError } = useContext(ApiErrorContext);
  const userProgressContext = useContext(UserProgressContext);
  const chapterNumber = userProgressContext.currentChapter;

  return (
    <div className="App">
      <QueryError
        open={
          !!apiError &&
          !(
            apiError instanceof HTTPNotFound ||
            apiError instanceof HTTPUnauthorized
          )
        }
        error={apiError}
      />
      <HeaderBar chapterNumber={chapterNumber} />
      <NotFoundProvider NotFound={NotFound}>
        <Switch>
          <Route exact path="/">
            <Story chapterNumber={chapterNumber} />
          </Route>
          <Route path="/story">
            <Story chapterNumber={chapterNumber} />
          </Route>
          <Route path="/info">
            <Info />
          </Route>
          <Route path="*">
            <NotFound />
          </Route>
        </Switch>
      </NotFoundProvider>
    </div>
  );
}

export default App;
