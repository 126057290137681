import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

export const FooterBar = () => {
  const history = useHistory();
  const location = useLocation();
  const goToInfo = () => {
    if (location.pathname !== '/info') {
      history.push('/info');
    }
    document.getElementsByTagName('html')[0].scrollTo(0, 0); //scrolling to top even if we're already on info page so button feels like it does something
  };
  return (
    <footer>
      <strong>Principal Investigator</strong>
      <br />
      David Schwebel, PhD
      <br />
      <br />
      &copy; 2021 Digital Artefacts - All Rights Reserved &nbsp;&nbsp;&nbsp;
      <button
        className="link"
        onClick={() => {
          goToInfo();
        }}
        type="button"
      >
        More Information
      </button>
    </footer>
  );
};

export default FooterBar;
