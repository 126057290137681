import React, { useContext, useState } from 'react';
import Vimeo from '@u-wave/react-vimeo';
import { useMutation } from 'react-query';
import { ApiContext } from '~app/api/context';
import { apiPost } from '~app/api/hooks';
import { UserProgressContext } from '~app/userprogress/context';
import Overlay from '~app/components/Overlay';
import constants from '~app/utils/constants';

export const VimeoPlayer = ({ vimid, vimtitle, islocked, uponCompletion }) => {
  const apiContext = useContext(ApiContext);
  const userProgressContext = useContext(UserProgressContext);
  const [currentEvent, setCurrentEvent] = useState('');
  const [showReward, setShowReward] = useState(false);
  const [showResumeArrow, setShowResumeArrow] = useState(false);
  const [showNetworkErrorMessage, setShowNetworkErrorMessage] = useState(false);

  const [doMutation] = useMutation(
    body =>
      apiPost(apiContext.url + '/event', body, {
        fetchOptions: {
          headers: { Authorization: `JWT ${apiContext.authToken}` },
        },
      }),
    {
      onSuccess: data => {
        setShowNetworkErrorMessage(false);
        if (
          currentEvent === 'complete' &&
          !userProgressContext.passedVideos.has(vimid)
        ) {
          userProgressContext.setPassedVideos(
            userProgressContext.passedVideos.add(vimid),
          );
          userProgressContext.setActivityCompletionCount(
            userProgressContext.passedGames.size +
              userProgressContext.passedVideos.size,
          );
          setShowReward(true);
          setShowResumeArrow(true);
          uponCompletion();
          setTimeout(() => {
            setShowResumeArrow(false);
          }, constants.BOUNCING_ARROW_DURATION);
        }
      },
      onError: error => {
        setShowNetworkErrorMessage(false);
        setTimeout(() => {
          setShowNetworkErrorMessage(true);
        }, 700);
      },
    },
  );

  const captureVideoEvent = (id, eventType) => {
    setCurrentEvent(eventType);
    doMutation({
      timestamp: new Date().toISOString(),
      type: 'video',
      type_identifier: id,
      description: vimtitle,
      action: eventType,
    });
  };

  return (
    <>
      <section
        className={`panel panel-vimeoPlayer ${
          islocked && !userProgressContext.passedVideos.has(vimid)
            ? 'panel-locks'
            : 'panel-unlocked'
        }`}
      >
        <h3>{vimtitle}</h3>
        <Vimeo
          video={`//vimeo.com/${vimid}`}
          onPlay={() => captureVideoEvent(vimid, 'begin')}
          onEnd={() => captureVideoEvent(vimid, 'complete')}
        />
        {showResumeArrow && <div className="panel__continueArrow"></div>}
      </section>
      <Overlay
        alertType="completion"
        isOpen={showReward}
        completedItem={`the ${vimtitle} video`}
        completionType="lesson"
      />
      <Overlay
        alertType="networkError"
        isOpen={showNetworkErrorMessage}
        continueAction={() => captureVideoEvent(vimid, currentEvent)}
      />
    </>
  );
};

export default VimeoPlayer;
