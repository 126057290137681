import React from 'react';
import Toast from 'react-bootstrap/Toast';

function QueryError({open, error}) {
  return (
    <Toast className="toast__api_error" show={open}>
      <Toast.Header closeButton={false}>
        API Error
      </Toast.Header>
      <Toast.Body>
        {error?.message ?? ''}
      </Toast.Body>
    </Toast>
  );
}

export default QueryError;
