import { useEffect, useState } from 'react';

//Hook for accessing browser's intersection observer api
export const useOnScreen = (
  ref,
  rootMargin = '0px',
  root = null,
  threshold = 0,
) => {
  // State and setter for storing whether element is visible
  const [isIntersecting, setIntersecting] = useState(false);
  useEffect(() => {
    let observerRefValue = null;
    const observer = new IntersectionObserver(
      entries => {
        setIntersecting(entries[0].isIntersecting);
      },
      {
        root,
        rootMargin,
        threshold,
      },
    );
    if (ref.current) {
      observer.observe(ref.current);
      observerRefValue = ref.current;
    }
    return () => {
      if (observerRefValue) observer.unobserve(observerRefValue);
    };
  }, [ref, root, rootMargin, threshold]);
  return isIntersecting;
};
