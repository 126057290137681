import React, { useEffect, useContext, useState, useRef } from 'react';
import { GAMES_URL } from '~app/env';
import { useMutation } from 'react-query';
import { ApiContext } from '~app/api/context';
import { apiPost } from '~app/api/hooks';
import { UserProgressContext } from '~app/userprogress/context';
import Overlay from '~app/components/Overlay';
import constants from '~app/utils/constants';

export const Unity = ({ id, title, islocked, uponCompletion }) => {
  const apiContext = useContext(ApiContext);
  const userProgressContext = useContext(UserProgressContext);
  const [showReward, setShowReward] = useState(false);
  const [showNetworkErrorMessage, setShowNetworkErrorMessage] = useState(false);
  const unityEmbedUrlBase =
    GAMES_URL +
    (userProgressContext.currentChapter > 1 ? '/ShootSafe/' : '/DinosHunt/');
  const unityEmbedUrl = id
    ? `${unityEmbedUrlBase}?game=${id}`
    : unityEmbedUrlBase;
  const [currentEvent, setCurrentEvent] = useState('');
  const [showResumeArrow, setShowResumeArrow] = useState(false);
  const notifyServerParams = useRef({});
  const [notifyServerOfGameAction] = useMutation(
    body =>
      apiPost(apiContext.url + '/event', body, {
        fetchOptions: {
          headers: { Authorization: `JWT ${apiContext.authToken}` },
        },
      }),
    {
      onSuccess: data => {
        setShowNetworkErrorMessage(false);
        if (
          currentEvent === 'complete' &&
          !userProgressContext.passedGames.has(id)
        ) {
          userProgressContext.setPassedGames(
            userProgressContext.passedGames.add(id),
          );
          userProgressContext.setActivityCompletionCount(
            userProgressContext.passedGames.size +
              userProgressContext.passedVideos.size,
          );
          uponCompletion();
          setShowReward(true);
          setShowResumeArrow(true);
          setTimeout(() => {
            setShowResumeArrow(false);
          }, constants.BOUNCING_ARROW_DURATION);
        }
      },
      onError: error => {
        setShowNetworkErrorMessage(false);
        // If network error message modal already open,
        // send it away and bring it back to indicate
        // error from new attempt rather than suggesting no action taken
        setTimeout(() => {
          setShowNetworkErrorMessage(true);
        }, 700);
      },
    },
  );

  useEffect(() => {
    const processMessage = event => {
      let messageData = event.data;
      if (typeof messageData === 'string') {
        // we expect messageData to be either an object or parsable string,
        // if a string, we try to parse it
        try {
          messageData = JSON.parse(messageData);
        } catch (parseError) {
          // if messageData is an unparsable string, we just keep initial value
        }
      }
      if (messageData.hasOwnProperty('gameId')) {
        let gameData = messageData?.gameData;
        if (gameData !== undefined) {
          //if undefined, we send that to server (which will accept that in lieu of an object)
          // otherwise we try to parse
          try {
            gameData = JSON.parse(gameData);
          } catch (parseError) {
            gameData = {
              error: 'Game supplied unparsable data',
              unparsable: gameData,
            };
            // if gameData is unparsable, we inform the server
            // via an object it will accept
          }
        }

        setCurrentEvent(messageData?.eventType);

        notifyServerParams.current = {
          timestamp: new Date().toISOString(),
          type: 'game',
          type_identifier: messageData.gameId, // should = id from component prop but we'll take it from here
          description: messageData.gameId, // gameId will be human-readable (albeit camel case with no spaces)
          action: messageData?.eventType || 'begin', //cannot be empty or arbitrary
          properties: gameData,
        };

        notifyServerOfGameAction(notifyServerParams.current);

        if (messageData?.eventType === 'begin') {
          if (userProgressContext.passedGames.has(id)) {
            window.frames['UnityEmbed'].unityGameInstance.SendMessage(
              'GameController',
              'SetGamePlayState',
              'replay',
            );
          } else {
            window.frames['UnityEmbed'].unityGameInstance.SendMessage(
              'GameController',
              'SetGamePlayState',
              'first-time',
            );
          }
        }
      }
    };
    window.addEventListener('message', processMessage);
    return () => {
      window.removeEventListener('message', processMessage);
    };
  }, [notifyServerOfGameAction, id, userProgressContext]);

  return (
    <section
      className={`panel panel-unity ${
        islocked && !userProgressContext.passedGames.has(id)
          ? 'panel-locks'
          : 'panel-unlocked'
      }`}
    >
      <iframe
        title="UnityEmbed"
        name="UnityEmbed"
        mozallowfullscreen="true"
        allow="fullscreen"
        src={unityEmbedUrl}
        msallowfullscreen="true"
        scrolling="no"
        allowFullScreen={true}
        webkitallowfullscreen="true"
        allowtransparency="false"
        frameBorder="0"
        loading="lazy"
      ></iframe>
      {showResumeArrow && <div className="panel__continueArrow"></div>}
      <Overlay
        alertType="completion"
        isOpen={showReward}
        completedItem={title}
        completionType="lesson"
      />
      <Overlay
        alertType="networkError"
        isOpen={showNetworkErrorMessage}
        continueAction={() => {
          notifyServerOfGameAction(notifyServerParams.current);
        }}
      />
    </section>
  );
};

export default Unity;
