class HTTPError extends Error {
  constructor(response, body) {
    super(`HTTP ${response.status}`);
    this.response = response;
    this.body = body;
  }

  static fromResponse(response, body) {
    if (response.status === 400) {
      return new HTTPBadRequest(response, body);
    }
    if (response.status === 401) {
      return new HTTPUnauthorized(response, body);
    }
    if (response.status === 403) {
      return new HTTPForbidden(response, body);
    }
    if (response.status === 404) {
      return new HTTPNotFound(response, body);
    }
    return new HTTPError(response, body);
  }
}

class HTTPBadRequest extends HTTPError { }
class HTTPNotFound extends HTTPError { }
class HTTPForbidden extends HTTPError { }
class HTTPUnauthorized extends HTTPError { }

export {
  HTTPBadRequest,
  HTTPError,
  HTTPForbidden,
  HTTPNotFound,
  HTTPUnauthorized,
};
