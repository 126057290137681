export function objectToSearchParams(obj) {
  const usp = new URLSearchParams();
  Object.keys(obj ?? {}).forEach(k => {
    if (Array.isArray(obj[k])) {
      obj[k].forEach(v => usp.append(k, v));
    } else {
      usp.append(k, obj[k]);
    }
  });
  return usp;
}
